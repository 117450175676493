<template>
    <section>
      <b-row>
        <b-col md="3" lg="3" v-for="stat in stats" :key="stat.id">
          <stat :variant="stat.variant" :icon="stat.icon" :name="stat.name" :value="stat.value" :unit="stat.unit"></stat>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col>
          <bruna-element-loader :dataUrl="climaChart00" loadingStrategy="ALWAYS">
          </bruna-element-loader>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col sm="12">
          <ag-grid-table ref="historicoClima" :debug="true" :configUrl="historicoClimaConfig"
            :dataUrl="historicoClimaData" @gridReady="onGridReady" @getRowsLoaded="onGetRowsLoaded"
            :paginationPageSize="50">
          </ag-grid-table>
        </b-col>
      </b-row>
    </section>
  </template>
          
  <script>
  
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  import Stat from '@/views/clients/base/components/Stat.vue'
  import useApiServices from '@/services/useApiServices.js';
  import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'
  
  import axios from "@axios";
  var qs = require('qs');
  
  import {
    BRow,
    BCol,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
  
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
  
    BOverlay,
    BSpinner
  
  
  } from "bootstrap-vue";
  
  export default {
    components: {
      AgGridTable,
      BRow,
      BCol,
      BButtonToolbar,
      BButtonGroup,
      BButton,
      BInputGroup,
      BFormInput,
      BFormGroup,
      BFormTextarea,
      BCard,
      BCardTitle,
      BCardSubTitle,
      BCardBody,
      BMedia,
      BMediaAside,
      BAvatar,
      BMediaBody,
      Stat,
      BOverlay,
      BSpinner,
      BrunaElementLoader
    },
  
    methods: {
  
      debugChange(value) {
        console.log(value)
        let decoded = JSON.parse(value)
      },
  
      onGridReady(gridApi, gridColumnApi, params) {
        this.gridApi = gridApi
        console.log("Planificaciones > onGridReady", { gridApi, gridColumnApi, params })
      },
  
      onGetRowsLoaded(gridApi, gridColumnApi, params) {
        console.log("Planificaciones > onGetRowsLoaded", { gridApi, gridColumnApi, params })
      },
  
      loadStats00Data() {
        axios.request({
          method: "get",
          url: this.stats00Url,
          headers: {
            Accept: "application/json",
          },
        })
          .then((response) => {
            this.stats = response.data
          })
      }
    },
  
    watch: {
      busqueda: {
        handler(newValue, oldValue) {
          let query = qs.stringify({ equipo: newValue.equipo, mes: newValue.meses, planta: newValue.planta });
          // console.log('QUERY ', newValue.equipo)
          // const default_fin = this.prediccion.fields[1].value;
          // let query = qs.stringify({ busqueda: newValue })
          // console.log('here => ', query);
  
          this.historicoClimaConfig = useApiServices.historicoData.clima.historicoConfig + "?" + query
          this.historicoClimaData = useApiServices.historicoData.clima.historicoData + "?" + query
  
          this.electricoChart00 = useApiServices.historicoData.clima.chart00 + "?" + query,
  
            this.stats00Url = useApiServices.historicoData.clima.stats00 + "?" + query,
  
            this.$refs['historicoClima'].refreshRows([], true);
          this.loadStats00Data();
  
        },
      }
    },
  
    props: {
      // fet_name: {
      //   type: String,
      //   required: true,
      //   default: null
      // },
      busqueda: {
        type: Object,
        required: true,
        default: () => null
      },
    },
  
    mounted() {
      this.loadStats00Data();
    },
  
    created() {
  
    },
  
    data() {
      let query = qs.stringify({ planta_name: 'Maipu' })
      // console.log('here => ', this.busqueda);
      return {
        showOverlay: false,
        overlayMessage: "Cargando...",
        gridApi: null,
        historicoClimaConfig: useApiServices.historicoData.clima.historicoConfig + "?" + query,
        historicoClimaData: useApiServices.historicoData.clima.historicoData + "?" + query,
        climaChart00: useApiServices.historicoData.clima.chart00 + "?" + query,
  
        stats00Url: useApiServices.historicoData.clima.stats00 + "?" + query,
  
        stats: [],
      };
    },
  };
  </script>
      
  <style lang="scss" scoped>
  @import "@core/scss/vue/libs/vue-slider.scss";
  
  .escenario-base {
    background-color: #00bcd4 !important;
  }
  
  span {
    font-size: 14px;
  }
  
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
          